<template>
  <div>
<!--    &lt;!&ndash; 新增按钮 &ndash;&gt;-->
<!--    <el-button type="success" @click="openDialog('add')">新增</el-button>-->
    <!-- 新增按钮靠右 -->
    <el-row>
      <el-col :span="24" style="text-align: right;">
        <el-button type="primary" @click="openDialog('add')" class="btn-button">新增</el-button>
      </el-col>
    </el-row>
    <!-- 表格展示 attrList -->
    <el-table :data="attrList" border>
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="name" label="属性名称"></el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="category" label="类别"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="openDialog('edit', scope.row)">编辑</el-button>
          <el-button type="danger" @click="deleteAttr(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible">
      <el-form :model="form" label-width="80px">
        <el-form-item label="属性名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
<!--        <el-form-item label="类型">-->
<!--          <el-input v-model="form.type"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="类型">
          <el-select v-model="form.type" placeholder="请选择类型">
            <el-option label="TEXT" value="TEXT"></el-option>
            <el-option label="URL" value="URL"></el-option>
            <!-- 可根据需要添加更多选项 -->
          </el-select>
        </el-form-item>
        <el-form-item label="类别">
          <el-select v-model="form.category">
            <el-option label="企业" value="企业"></el-option>
            <el-option label="个人" value="个人"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveAttr">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {post} from "@/apis/restUtils";

export default {
  data() {
    return {
      attrList: [], // 存储从接口获取的属性列表
      dialogVisible: false, // 控制对话框显示
      form: { // 表单数据
        id: null,
        name: '',
        type: '',
        category: '' // 存储“企业”或“个人”
      },
      dialogTitle: '', // 对话框标题
      isEdit: false // 区分新增还是编辑
    };
  },
  mounted() {
    this.fetchAttrList(); // 页面加载时获取数据
  },
  methods: {
    // 获取属性列表
    fetchAttrList() {
      post('/crm/getAttrs', {}, this.fetchAttrListConsumer);
    },

    fetchAttrListConsumer(response) {
      if (response && response.data && response.data.entity) {
        // 将后台的 category ('1' 或 '2') 转义为“企业”或“个人”
        this.attrList = response.data.entity.map(item => ({
          ...item,
          category: item.category === '1' ? '企业' : '个人'
        }));
      }
    },

    // 打开新增或编辑对话框
    openDialog(type, row) {
      this.dialogVisible = true;
      if (type === 'add') {
        this.dialogTitle = '新增属性';
        this.isEdit = false;
        this.form = {id: null, name: '', type: '', category: ''};
      } else {
        this.dialogTitle = '编辑属性';
        this.isEdit = true;
        this.form = {...row};
      }
    },
    // 保存数据（新增或编辑）
    saveAttr() {
      // 将“企业”或“个人”转义为 '1' 或 '2'
      const categoryCode = this.form.category === '企业' ? '1' : '2';
      const dataToSend = {
        ...this.form,
        category: categoryCode
      };
      console.log("dataToSend ...", dataToSend);
      if (this.isEdit) {
        // 编辑操作
        post('/crm/attr/update', dataToSend, response => {
          if (response && response.data.status === 'success') {
            this.$message.success('更新成功');
            this.fetchAttrList(); // 刷新列表
            this.dialogVisible = false;
          }
        });
      } else {
        // 新增操作
        post('/crm/attr/add', dataToSend, response => {
          if (response && response.data && response.data.status === 'success') {
            this.$message.success('新增成功');
            this.fetchAttrList(); // 刷新列表
            this.dialogVisible = false;
          }
        });
      }
    },
    // 删除数据
    deleteAttr(id) {
      this.$confirm('确认删除该属性?', '提示', {
        type: 'warning'
      }).then(() => {
        post('/crm/attr/delete', {'id': id}, response => {
          if (response && response.data && response.data.status === 'success') {
            this.$message.success('删除成功');
            this.fetchAttrList(); // 刷新列表
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.crm-attr {
  padding: 20px;
}

.el-table {
  margin-top: 20px;
}

.dialog-footer {
  text-align: right;
}

.btn-button {
  margin-right: 100px; /* 按钮四边都有10像素的外边距 */
  margin-top: 20px; /* 按钮四边都有10像素的外边距 */
}

</style>